import { Datagrid, TextField, List, ReferenceField, FieldProps, SelectInput, ReferenceInput } from 'react-admin'
import { DisplayNameField } from '../../core/DisplayNameField'
import { ResultField } from '../../core/ResultField'

const RowNumberField: React.FC<FieldProps> = () => {
  return <></>
}

const filters = [
  <ReferenceInput alwaysOn source="divisionId" reference="divisions">
    <SelectInput optionText="label" />
  </ReferenceInput>,
]

export const Table = () => (
  <List resource="sessions" title="Leaderboards" sort={{ field: 'score', order: 'DESC' }} filter={{ status: 'complete', type: 'leaderboards' }} filters={filters} exporter={false}>
    <Datagrid sx={{ '& .column-rank': { width: '20px' } }} className="ranked" bulkActionButtons={false} size="medium">
      <RowNumberField label="Rank" source='rank'/>

      <ReferenceField label="Athlete" source="userId" reference="users" sortable={false}>
        <DisplayNameField />
      </ReferenceField>

      <ReferenceField label="Athlete" source="userId" reference="users" sortable={false}>
        <ReferenceField source="divisionId" reference="divisions" sortable={false}>
          <TextField source="label" />
        </ReferenceField>
      </ReferenceField>

      <TextField source="score" />

      <ResultField label="Result" />
    </Datagrid>
  </List>
)


