import { CreateEvent } from './create'
import { EditEvent } from './edit'
import { Table } from './table'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'

export class Events {
  static Edit = EditEvent
  static List = Table
  static Create = CreateEvent
  static Icon = EventOutlinedIcon
}
