import { Datagrid, TextField, List } from 'react-admin'

export const Table = () => (
  <List exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="label" />
      <TextField source="description" />
      <TextField source="public" />
    </Datagrid>
  </List>
)
