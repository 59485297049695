import {
  AutocompleteInput,
  BooleanInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

import countries from 'flag-icons/country.json'

const countryOptions = countries.map((c) => ({ id: c.code, name: c.name }))

export const CreateUser = () => {
  return (
    <Create title="New Athlete Registration">
      <SimpleForm redirect={'list'} style={{ maxWidth: 400 }}>
        <TextInput source="name" fullWidth validate={[required()]} />
        <TextInput source="email" fullWidth validate={[required()]} />
        <AutocompleteInput
          source="country"
          choices={countryOptions}
          fullWidth
          defaultValue={'us'}
          validate={[required()]}
        />
        <ReferenceInput label="Division" source="divisionId" reference="divisions">
          <SelectInput optionText="label" fullWidth validate={[required()]} />
        </ReferenceInput>
        <BooleanInput source="waiver" label="Waiver Accepted" />
      </SimpleForm>
    </Create>
  )
}
