import { ReferenceInput, SelectInput, useShowController, Edit, SimpleForm, ReferenceField, TextInput, Toolbar, SaveButton, useRecordContext } from 'react-admin'
import { VideoJS } from './VideoJS'
import { DisplayNameField } from '../../core/DisplayNameField'
import './show.css'

export const EditSession = (props: any) => {

  const UserEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
  )

  const transform = (data: any) => {
    let score = 0
    // iterate over previous result keys to map new value
    Object.keys(data.result).map(function(key: any) {
      if (key === 'Rest') return
      score += parseInt(data[key])
      data['result'][key] = parseInt(data[key])
    })

    data['score'] = score

    return ({
      ...data,
      userUpdate: true
    })
  }

  const ResultsField = () => {
    return (
      <div style={{marginTop: 20}}>
        {record && record.result && Object.keys(record.result).map(function(key: any) {
          if (key === 'Rest') return (<></>)

          return (
            <TextInput style={{margin: 10}} label={key} source={key} defaultValue={record.result[key]} />
          )
        })}
      </div>
    )
  }

  const { record } = useShowController({ resource: 'sessions' })

  return (
    <>
      <div style={{ marginTop: 60, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '300px', height: '500px' }}>
          <VideoJS
            id={'athlete1'}
            src={record && record.videoUrl ? record.videoUrl : ''}
            isPlaying={true}
            showControls={true}
            isLive={false}
            replay={false}
            onEvent={() => {}}
          ></VideoJS>
        </div>
      </div>
      <Edit transform={transform}>
        <SimpleForm toolbar={<UserEditToolbar />}>
          <ReferenceField source="userId" reference="users" label="Athlete">
            <DisplayNameField color="white" />
          </ReferenceField>
          <ResultsField />
          <ReferenceInput label="Division" source="divisionId" reference="divisions">
            <SelectInput optionText="label" fullWidth />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  )
}
