import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin'

import countries from 'flag-icons/country.json'

const countryOptions = countries.map((c) => ({ id: c.code, name: c.name }))

export const EditUser = () => {
  return (
    <Edit title="Update Athlete Registration">
      <SimpleForm style={{ maxWidth: 400 }}>
        <TextInput source="name" fullWidth validate={[required()]} />
        <TextInput source="email" fullWidth validate={[required()]} />
        <TextInput source="displayName" fullWidth />
        <AutocompleteInput source="country" choices={countryOptions} fullWidth />
        <ReferenceInput label="Division" source="divisionId" reference="divisions">
          <SelectInput optionText="label" fullWidth />
        </ReferenceInput>
        <BooleanInput source="waiver" label="Waiver Accepted" />
      </SimpleForm>
    </Edit>
  )
}
