import { CreateUser } from './create'
import { EditUser } from './edit'

import { Table } from './table'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'

export class Users {
  static List = Table
  static Create = CreateUser
  static Edit = EditUser
  static Icon = PeopleAltOutlinedIcon
}
