import buildGraphQLProvider from 'ra-data-graphql-simple'
import customBuildQuery from '../../custom_build_query'
import { apiClient } from '../../apiClient'

export const getDataProvider = async () => {
  try {
    const dataProvider = await buildGraphQLProvider({
      buildQuery: customBuildQuery,
      client: apiClient,
      introspection: {
        operationNames: {},
      },
    })

    return dataProvider
  } catch (e) {
    console.log('error in init DataProvider', e)
  }
}
