import { AppBar, ToggleThemeButton, defaultTheme, AppBarProps, Layout, LayoutProps } from 'react-admin'
import { Typography } from '@mui/material'
import { PaletteMode } from '@mui/material'
import logo from './images/combined-logo.svg'

const darkTheme = {
  palette: {
    mode: 'dark' as PaletteMode, // Switching the dark mode on is a single property value change.
  },
}

import { IconButton } from '@mui/material'

// import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
// import CloudIcon from '@mui/icons-material/Cloud'
import CloudDoneTwoToneIcon from '@mui/icons-material/CloudDoneTwoTone'
import CloudOffIcon from '@mui/icons-material/CloudOff'

import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase'
import PhoneAndroidTwoToneIcon from '@mui/icons-material/PhoneAndroidTwoTone'

const NetworkIndicator = ({ webSocket = false, device = false }: { webSocket?: boolean, device?: boolean }) => (
  <>
    <IconButton color="inherit" >{webSocket ? <CloudDoneTwoToneIcon /> : <CloudOffIcon />}</IconButton>
    <IconButton color="inherit" >{device ? <PhoneAndroidTwoToneIcon /> : <PhonelinkEraseIcon />}</IconButton>
  </>
)

export const CustomAppBar = (props: JSX.IntrinsicAttributes & AppBarProps) => (
  <AppBar {...props}>
    <img src={logo} style={{ maxHeight: 24 }} />
    <Typography flex="1" variant="h6" id="react-admin-title"></Typography>
    <ToggleThemeButton lightTheme={defaultTheme} darkTheme={darkTheme} />
    <NetworkIndicator />
  </AppBar>
)

export const CustomLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => (
  <Layout {...props} appBar={CustomAppBar} />
)
