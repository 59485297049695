import React from 'react'
import { Admin, CustomRoutes, ListGuesser, Resource } from 'react-admin'
import { Route } from 'react-router-dom'

import mockAuthProvider from './mockAuth'
import authProvider from './provider'

import mockDataProvider from './data-providers/mock'
import { getDataProvider } from './data-providers/graphql'

import AdminResources from './resources'
import { CustomLayout } from './CustomLayout'

import 'flag-icons/css/flag-icons.min.css'
import './App.css'
import { RegistrationView } from './views/RegistrationView'
import { LiveView } from './views/live'
import { LoginPage } from './login'
import { LeaderboardView } from './views/leaderboard'
import { WaiverView } from './views/waiver'
import { createTheme } from '@mui/material'
import { HomeView } from './views/home'

function App() {
  const [dataProvider, setDataProvider] = React.useState<any>()

  const fetchDataProvider = async () => {
    const dataProvider = await getDataProvider()
    setDataProvider(dataProvider)
  }

  React.useEffect(() => {
    fetchDataProvider()
  }, [])

  if (!dataProvider) return <>Loading</>

  const darkTheme = createTheme({
    palette: { mode: 'dark' },
  })

  const config = import.meta.env.DEV ? {
    authProvider: mockAuthProvider,
    dataProvider: mockDataProvider
  } : {
    authProvider: authProvider,
    dataProvider: dataProvider
  }

  return (
    <Admin
      disableTelemetry
      authProvider={config.authProvider}
      loginPage={LoginPage}
      theme={darkTheme}
      dashboard={HomeView}
      layout={CustomLayout}
      dataProvider={config.dataProvider}
    >
      <CustomRoutes noLayout>
        <Route path="/" element={<HomeView />} />
        <Route path="/register" element={<RegistrationView />} />
        <Route path="/live" element={<LiveView />} />
        <Route path="/leaderboard" element={<LeaderboardView />} />
        <Route path="/waiver" element={<WaiverView />} />
      </CustomRoutes>

      <Resource
        name="queue"
        options={{ label: 'Live Stage' }}
        list={AdminResources.Queue.List}
        edit={AdminResources.Queue.Edit}
        icon={AdminResources.Queue.Icon}
      />

      <Resource
        name="leaderboards"
        options={{ label: 'Leaderboards' }}
        list={AdminResources.Leaderboards.List}
        edit={AdminResources.Leaderboards.Edit}
        icon={AdminResources.Leaderboards.Icon}
      />

      <Resource
        name="divisions"
        options={{ label: 'Divisions' }}
        list={AdminResources.Divisions.List}
        edit={AdminResources.Divisions.Edit}
      />

      <Resource
        name="users"
        options={{ label: 'Athletes' }}
        icon={AdminResources.Users.Icon}
        list={AdminResources.Users.List}
        edit={AdminResources.Users.Edit}
        create={AdminResources.Users.Create}
      />

      <Resource
        name="events"
        icon={AdminResources.Events.Icon}
        list={AdminResources.Events.List}
        edit={AdminResources.Events.Edit}
      // create={AdminResources.Events.Create}
      />

      <Resource
        name="sessions"
        icon={AdminResources.Sessions.Icon}
        list={AdminResources.Sessions.List}
        edit={AdminResources.Sessions.Edit}
        show={AdminResources.Sessions.Show}
      />

      <Resource name="divisions" />
    </Admin>
  )
}

export default App
