import { EditSession } from './edit'
import { ShowSession } from './show'
import { Table } from './table'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'

export class Sessions {
  static List = Table
  static Edit = EditSession
  static Show = ShowSession
  static Icon = ListAltOutlinedIcon
}
