import { useState, useEffect, useRef } from 'react'
import { ShowSession } from '../../resources/sessions/show'
import { webSocket } from '../../websocket'

import './live.css'

export function LiveView(props: any) {
  const [sessionId, setSessionId] = useState<any>(null)
  const [socketMessage, setSocketMessage] = useState<any>({})
  const [wsStatus, setWsStatus] = useState<number>(3)

  var httpProtocol = 'http://' 
  var wsProtocol = 'ws:'

  if (window.location.protocol === 'https:') {
      httpProtocol = 'https://'
      wsProtocol = 'wss:'
  }

  function connect() {
    let webSocket = new WebSocket(`${wsProtocol}//${window.location.host}/live`)

    webSocket.onopen = (event: any) => {
      console.log('connected')
      
      setWsStatus(1)
    }

    webSocket.onmessage = function (event: any) {
      let json = JSON.parse(event.data)

      if (json['state']) {
        setSocketMessage(json)
      }

      switch (json['event']) {
        case 'end_workout': {
          setTimeout(function() {
            setSessionId(null)
          }, 10000)
        }
        case null: {}
        default: {
          if (!sessionId) setSessionId(json.data.id)
        }
      }
    }

    webSocket.onclose = function(event: any) {
      console.log('lost connection')

      setWsStatus(0)

      setTimeout(function() {
        connect()
      }, 50)
    }

    webSocket.onerror = function(event: any) {
      console.log('error')
    }
  }

  // initialize once
  useEffect(() => {
    connect()

    return () => webSocket.close()
  }, [webSocket])

  useEffect(() => { }, [sessionId])

  return <ShowSession sessionId={sessionId} socketMessage={socketMessage} isLive={true} wsStatus={wsStatus} />
}
