import { FC } from 'react'
import { useRecordContext, FieldProps } from 'react-admin'
import Pluralize from 'pluralize'

export const ResultField: FC<FieldProps> = () => {
  const record = useRecordContext()

  if (!record) return null
  if (!record['result']) return null

  const total = Object.keys(record['result'])
    .map((key: string | number) => {
      if (key === 'Rest') {
        return 0
      } else {
        return record['result'][key]
      }
    })
    .reduce((a: any, b: any) => a + b)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontSize: '200%', fontWeight: 'bold' }}>{total}</span>
      <div style={{ lineHeight: 1.1, marginLeft: 8, fontSize: ' 80%' }}>
        {Object.keys(record['result']).map((key, index) => {
          if (key === 'Rest') {
            return
          } else {
            const exercise = key
            const reps = record['result'][key]
            return <div key={index}>{Pluralize(exercise, reps, true)}</div>
          }
        })}
      </div>
    </div>
  )
}
