import { User } from './types'
import { generateFetchRequest } from './fetch'
import AppState from './app_state'

type LoginCredentials = { email: string; password: string; rememberMe: boolean }
type LoginResponse = { success?: boolean; resource?: User }
type Identity = { id: string; fullName?: string; avatar?: string; user: User }

const authProvider = {
  login: async ({ password }: LoginCredentials): Promise<void | User> => {
    const request = generateFetchRequest({
      body: JSON.stringify({
        user: { password },
      }),
      method: 'POST',
      path: 'sign_in',
    })

    return fetch(request).then(async (response) => {
      const data = await response.json()
      if (response.status != 200) {
        throw new Error(data.message)
      }

      const { body: user } = data

      await AppState.setIdentity({ user })

      return user
    })
  },
  checkError: (error: { status: number }): Promise<void> => {
    // called when dataProvider returns an error
    // rejected promise automatically calls authProvider.logout()

    // if (error && error.networkError && [401, 403].includes(error.networkError.statusCode)) {
    if (error && error.status && [401, 403].includes(error.status)) {
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: (): Promise<void> => {
    // AUTHENTICATION
    // Checking Credentials During Navigation
    // called each time the user navigates to a list, edit, create or show page
    // rejected promise automatically calls authProvider.logout()

    if (AppState.getCurrentUser() != null) return Promise.resolve()
    return Promise.reject()
  },
  getPermissions: (): Promise<string[]> => {
    // AUTHORIZATION

    return Promise.resolve(AppState.getRoles())
  },
  getIdentity: (): Promise<any> => {
    const { user } = AppState.getIdentity()
    try {
      return Promise.resolve({ id: user ? user.id : null, user })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  logout: (): Promise<void> => {
    // called on user logout (logout button, checkError rejection, checkAuth rejection)
    // should handle current authentication data clean up (i.e. reset local storage)

    const request = generateFetchRequest({
      method: 'POST',
      path: 'sign_in',
    })

    return fetch(request).then((response) => {
      AppState.reset()
    })
  },
}

export default authProvider
