import { FC, useEffect } from 'react'
import { Datagrid, List, ReferenceField, TextField, EditButton, SearchInput, SelectInput, FieldProps, DeleteButton, NumberField } from 'react-admin'
import { UserField } from '../../core/UserField'
import { ResultField } from '../../core/ResultField'
import { TimeAgoField } from '../../core/TimeAgoField'

import { Grid } from '@mui/material'

const filters = [
  <SearchInput alwaysOn source="q" />,
  <SelectInput alwaysOn source="status" choices={[
    { id: 'complete', name: 'Complete' },
    { id: 'queued', name: 'Queued' }]} />
]


import SlideshowIcon from '@mui/icons-material/Slideshow'

import { useRecordContext } from 'react-admin'

export const EnhancedStatusField: FC<FieldProps> = () => {
  const record = useRecordContext()
  const video = record && record['videoUrl'] ? <SlideshowIcon /> : null

  return (
    <Grid container direction="row" alignItems="center" >
      {video} {record && record['status']}
    </Grid>
  )
}

export const Table = () => {
  useEffect(() => {
    const appbar = document.querySelectorAll('header')
    appbar.forEach(appbar => {
      appbar.style.display = "block"
    })
  }, [])

  return (
    <List
      exporter={false}
      filters={filters}
      filter={{ type: "sessions" }}
      sx={{
        '& .MuiToolbar-root form': {
          marginTop: '4px !important',
          alignItems: 'center !important'
        },
      }}
    >
      <Datagrid bulkActionButtons={false} sort={{ field: 'score', order: 'DESC' }} rowClick="show">
        <ReferenceField source="userId" reference="users" label="Athlete" sortable={false}>
          <UserField />
        </ReferenceField>
        <ReferenceField source="competitorSessionId" reference="sessions" label="Competitor" sortable={false}>
          <ReferenceField source="userId" reference="users">
            <TextField source="name" sortable={false} />
          </ReferenceField>
        </ReferenceField>

        <EnhancedStatusField label="Status" />

        <NumberField source="score" />

        {/* <ResultField source="result" sortable={false} /> */}

        <ReferenceField label="Athlete" source="userId" reference="users" sortable={false}>
          <ReferenceField source="divisionId" reference="divisions" sortable={false}>
            <TextField source="label" />
          </ReferenceField>
        </ReferenceField>

        <TimeAgoField source="updatedAt" label="Updated" />

        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}
