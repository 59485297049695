import { useEffect, useState } from 'react'

const compete2022Wod = {
  name: 'Compete 2022',
  description: 'Thruster / Kettlebell Swing AMREP',
  sequence: [
    { name: 'Get Ready', time: 4 },
    { name: 'Thrusters', time: 30 },
    { name: 'Rest', time: 15 },
    { name: 'Kettlebell Swings', time: 30 },
  ],
  totalSec: 79,
}

const formatElapsed = (msec: number) => {
  const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, msec))

  return `0:${String(d.getUTCSeconds()).padStart(2, '0')} `
}

let intervalId: number | undefined = undefined

export const LiveProgress = (props: any) => {
  const [running, setRunning] = useState(false)
  const [elapsed, setElapsed] = useState(0)
  const [firstPlay, setFirstPlay] = useState(false)

  const wod = compete2022Wod

  useEffect(() => {
    if (!props.didStart) return

    if (props.isLive) {
      setRunning(true)
    } else {
      if (firstPlay) {
        setRunning(true)
      } else {
        setTimeout(() => {
          setFirstPlay(true)
          setRunning(true)
        }, 3000)
      }
    }
  }, [props.didStart])

  useEffect(() => {
    if (!props.replay) return
    setElapsed(0)
    setRunning(false)
  }, [props.replay])

  useEffect(() => {
    if (running) {
      intervalId = setInterval(() => {
        setElapsed((prev) => prev + 10)
      }, 10)
    } else {
      clearInterval(intervalId)
    }
  }, [running])
  
  const current = compete2022Wod.sequence.map((sequence, index) => {
    const addTimeSequence = compete2022Wod.sequence.slice(0, index + 1).reduce((partialSum, partialSequence) => partialSum + partialSequence.time, 0) * 1000
    if (elapsed < addTimeSequence) return index 
  }).filter(function( sequence ) {
    return sequence !== undefined
  })[0]

  const remainingInSet = compete2022Wod.sequence.map((sequence, index) => {
    const addTimeSequence = compete2022Wod.sequence.slice(0, index + 1).reduce((partialSum, partialSequence) => partialSum + partialSequence.time, 0) * 1000
    
    return addTimeSequence - elapsed
  })[current ? current : 0]

  if (elapsed == wod.totalSec * 1000) {
    console.log(`Elapsed: ${elapsed} ${running} - Ending Live Progress`)
    if (running) setRunning(false)

    let winner = props.score1 > props.score2 ? 
      props.athlete1 && props.athlete1.userDisplayName ? 
        `${props.athlete1.userDisplayName} Wins!` : 'Athlete 1 Wins' : 
      props.athlete2 && props.athlete2.userDisplayName ? 
        `${props.athlete2.userDisplayName} Wins!` : 'Athlete 2 Wins'

    if (props.score1 === props.score2) {
      winner = 'Tie!'
    }

    return <div className="wod-set">{winner}</div>
  } else {
    return (
      <>
        <div className="wod-set">{wod.sequence[current ? current : 0].name.toUpperCase()}</div>
        <div className="wod-timer">{formatElapsed(remainingInSet)}</div>
        {current && current < 2 && remainingInSet < 4000 ? (
          <div className="wod-next">
            NEXT: {wod.sequence[current + 1].time}s {wod.sequence[current + 1].name}
          </div>
        ) : (<></>)}
      </>
    )
  }
}
