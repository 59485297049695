export const csrfToken = () => {
  const meta = document.querySelector('meta[name=csrf-token]')
  return meta
}

interface GenerateFetchClientProps {
  body?: string
  headers?: { [key: string]: string }
  method: string
  path: string
}

export const generateFetchRequest = ({ body, headers = {}, method, path }: GenerateFetchClientProps): Request => {
  const requestArgs: { method: string; headers: Headers; body?: string } = {
    method: method,
    headers: new Headers(
      Object.assign(
        {
          'Content-Type': 'application/json',
        },
        headers
      )
    ),
  }

  if (!!body) requestArgs.body = body

  const request = new Request(`//${window.location.host}/${path}`, requestArgs)

  return request
}
