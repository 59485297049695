import { Datagrid, TextField, List } from 'react-admin'

export const Table = () => (
  <List
    exporter={false}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField source="title" />
      <TextField source="location" />
      <TextField source="startDate" />
    </Datagrid>
  </List>
)
