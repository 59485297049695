import { useRecordContext } from 'react-admin'

export const DisplayNameField = ({ color = 'inherit' }) => {
  const record = useRecordContext()
  const flag = <span style={{ marginLeft: 10 }} className={`fi fi-${record['country'] || 'us'}`}></span>
  return (
    <span>
      {flag}
      <span style={{ marginLeft: 10, fontWeight: 400, textTransform: 'uppercase', color: color }}>{record && record['displayName']}</span>
    </span>
  )
}
