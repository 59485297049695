import { Edit, SimpleForm, TextInput, DateTimeInput, required } from 'react-admin'

export const EditEvent = (props: any) => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="title" validate={[required()]} />
        <TextInput source="location" />
        <DateTimeInput source="start_date" />
      </SimpleForm>
    </Edit>
  )
}
