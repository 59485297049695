import { faker } from '@faker-js/faker'

import countries from 'flag-icons/country.json'
// const countryOptions = countries.map((c) => ({ id: c.code, name: c.name }))

const base = () => {
  const name = faker.name.findName()

  return {
    id: faker.datatype.uuid(),
    name: name,
    displayName: name.split(' ')[0] + ' ' + name.split(' ')[1][0],
    country: ['us', 'us', 'us', 'ca', 'mx', countries.sample().code].sample(),
    email: faker.internet.email(),
    createdAt: faker.date.recent(),
    updatedAt: faker.date.recent(),
  }
}

const adminExtras = () => ({})
// const managerExtras = () => ({})

const admin = () => ({ ...base(), ...adminExtras() })
// const manager = () => ({ ...base(), ...managerExtras() })

export class user {
  static asAdmin = admin
  // static asCompany = manager
}
