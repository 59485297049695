import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin'

export const EditQueue = (props: any) => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput label="Name" source="name" />
        <SelectInput
          source="status"
          choices={[
            { id: 'programming', name: 'Programming' },
            { id: 'lifestyle', name: 'Lifestyle' },
            { id: 'photography', name: 'Photography' },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}
