import fakeDataProvider from 'ra-data-fakerest'
import { division } from './division'
import { event } from './event'
import { user } from './user'
import { session } from './session'
import { faker } from '@faker-js/faker'

declare global {
  interface Array<T> {
    sample(): T
  }
}

Array.prototype.sample = function () {
  return this[Math.floor(Math.random() * this.length)]
}

const mainEvent = {
  id: faker.datatype.uuid(),
  title: '2022 Crossfit Games',
  location: 'Madison, Wisconsin',
  startDate: '2022-09-03',
  endDate: '2022-08-07',
  createdAt: faker.date.recent(),
  updatedAt: faker.date.recent(),
}

const divisions = [
  Object.assign(division.asAdmin(), {
    key: 'mens',
    label: "Men's Division",
    description: '',
  }),
  Object.assign(division.asAdmin(), {
    key: 'womens',
    label: "Women's Division",
    description: '',
  }),
]

const events = [mainEvent, ...Array.from({ length: 1 }, () => event.asAdmin())] // add relationship
const users = Array.from({ length: 75 }, () =>
  Object.assign(user.asAdmin(), {
    divisionId: divisions.sample().id,
  })
)

const sessions = Array.from({ length: 15 }, () => {
  const user = users.sample()

  return Object.assign(session.asAdmin(), {
    userId: user.id,
    userDisplayName: user.displayName,
    eventId: events.sample().id,
    divisionId: divisions.sample().id,
    competitorSessionId: null,
    completeAt: null,
    result: null,
    score: 0,
  })
})

sessions.map((s) => {
  // @ts-ignore ts2322
  s.competitorSessionId = sessions.filter((s) => s.status == 'complete').sample().id
})

sessions
  .filter((s) => s.status == 'complete')
  .map((s) => {
    // @ts-ignore ts2322
    s.completeAt = faker.date.recent()
    // @ts-ignore ts2322
    s.result = {
      thrusters: Math.floor(Math.random() * 20),
      swings: Math.floor(Math.random() * 30),
    }
    // @ts-ignore 2531
    s.score = s.result.thrusters + s.result.swings
  })

export default fakeDataProvider(
  {
    divisions,
    events,
    users,
    sessions,
  },
  true
)
