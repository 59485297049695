import { useEffect } from 'react'
import { useRefresh, ReferenceManyField, Datagrid, useGetList } from 'react-admin'
import { webSocket } from '../../websocket'
import { LeaderboardNameField } from '../../core/LeaderboardNameField'

import logo from '../../images/combined-logo.svg'

import './leaderboard.css'
import { ResultField } from '../../core/ResultField'

export function LeaderboardView(props: any) {
  const refresh = useRefresh()

  const { data: divisions, isLoading, error } = useGetList('divisions')

  const updateView = () => {
    refresh()
  }

  useEffect(() => {

    webSocket.onmessage = function (event: any) {
      updateView()
    }

    return () => webSocket.close()
  }, [])

  const RowNumberField: React.FC<any> = () => (<></>)

  if (isLoading) return <>Loading...</>
  if (error) return <>Error</>
  if (!divisions) return null

  return (
    <div style={{ textAlign: 'center', padding: 40 }}>
      <div style={{ textAlign: 'center' }}>
        <div className="live-header">
          <div style={{ alignItems: 'center' }}>
            <img src={logo} style={{ maxHeight: 40 }} />
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
      </div>
      <div className="division-wrapper">

        {divisions.map((division) => {
          if (division.public === false) return (<></>)

          return (
            <div className="leaderboard-division" style={{ marginBottom: 40 }}>
              <h2 style={{ textTransform: 'uppercase' }}>{division.label}</h2>
              <ReferenceManyField reference="sessions" sort={{ field: 'score', order: 'DESC' }} filter={{ status: 'complete', divisionId: division.id, type: 'leaderboard' }} target="post_id">
                <Datagrid className="ranked" bulkActionButtons={false}>
                  <RowNumberField label="" source='rank' sortable={false} />
                  <LeaderboardNameField />
                  <ResultField label="" sortable={false} />
                </Datagrid>
              </ReferenceManyField>
            </div>
          )
        })}
      </div>
    </div>
  )
}
