import { buildQuery } from 'ra-data-graphql-simple'
import { IntrospectionResult } from 'ra-data-graphql'

const capitalizeStr = (word: string, lowerRest = true): string => {
  if (word.length == 0) return word

  const first = word[0]
  const rest = word.slice(1)

  return first.toUpperCase() + (lowerRest ? rest.toLowerCase() : rest).slice(0, -1)
}

const resourcePathToResource = (resourcePath: string): string => {
  const path = resourcePath.includes('/') ? resourcePath.split('/').pop() : resourcePath

  if (path == undefined) throw `Invalid path in resource`

  const resource = path
    .replace('_', ' ')
    .split(' ')
    .map((sP) => capitalizeStr(sP))
    .join('')

  return resource
}

export default (introspection: IntrospectionResult) => (fetchType: string, resource: string, params: any) => {
  resource = resourcePathToResource(resource)
  
  if (params && params['sort']) {
    params['filter']['sort'] = params['sort']
  }

  return buildQuery(introspection)(fetchType, resource, params)
}
