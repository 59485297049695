import { FieldProps, useRecordContext } from 'react-admin'

export const UserField: React.FC<FieldProps & { showFlag?: boolean }> = ({ showFlag = true, source = 'displayName' }) => {
  const record = useRecordContext()
  const flag = showFlag && <span className={`fi fi-${record['country'] || 'us'}`}></span>

  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {flag}
      <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{record && record[source]}</span>
    </span>
  )
}
