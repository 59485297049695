// import 'antd/dist/antd.css'
import { useState, useEffect } from 'react'
import { ReferenceField, useShowController, RecordContextProvider, Button } from 'react-admin'

import { DisplayNameField } from '../../core/DisplayNameField'

import logo from '../../images/combined-logo.svg'

import { LiveProgress } from './LiveProgress'
import './show.css'
import { VideoJS } from './VideoJS'

const WaitingView = (props: any) => (
  <div style={{textAlign: 'center'}}>
    {props.wsStatus == 1 ? '🟢' : '⚪️' }
    <div style={{ fontSize: '24px', textAlign: 'center' }}>
      Waiting for next session...
    </div>
  </div>
)

export const ShowSession = (props: any) => {
  const [score1, setScore1] = useState<number>(0)
  const [score2, setScore2] = useState<number>(0)
  const [videoUrl, setVideoUrl] = useState<any>(null)
  const [showHint, setShowHint] = useState(false)
  const [hintMessage, setHintMessage] = useState('')
  const [showHintTwo, setShowHintTwo] = useState(false)
  const [hintMessageTwo, setHintMessageTwo] = useState('')
  const [started, setStarted] = useState(false)
  const [replay, setReplay] = useState(false)

  function processUpdate(target: string, update: any) {

    try {
      switch (update.type) {
        case 'rep': {
          const newScore = update.state.rep_count

          if (target == 'athlete1') {
            setScore1(newScore)
            setShowHintTwo(false)
          } else {
            setScore2(newScore)
            setShowHint(false)
          }

          break
        }
        case 'workout_start': {
          // TODO
          if (target == 'athlete2') {
            setStarted(true)
          }

          if (!props.isLive && !record.competitorSessionId) {
            setStarted(true)
          }

          break
        }
        case 'workout_end': {
          setShowHint(false)
          setShowHintTwo(false)
          setStarted(false)
          break
        }
        case 'exercise_start': {
          // TODO
          break
        }
        case 'exercise_end': {
          if (target == 'athlete2') {
            setShowHint(false)
          }
          // TODO
          break
        }
        case 'norep': {
          if (target == 'athlete2') {
            setShowHint(true)

            if (update.say) {
              setHintMessage(update.say)
            }

          } else {
            setShowHintTwo(true)

            if (update.say) {
              setHintMessageTwo(update.say)
            }
          }

          // TODO
          break
        }
        case 'count_in': {
          // TODO
          break
        }
        default: {
          console.log(`UNHANDLED '${update.type}'`)
        }
      }
    } catch (error) {
      console.error(error)
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }

  const onError = (error: any) => {
    // console.log(error)
  }

  const replaySession = () => {
    setReplay(true)
    setShowHint(false)
    setShowHintTwo(false)
    setScore1(0)
    setScore2(0)
    setStarted(false)
    
    setTimeout(() => {
      setReplay(false)
    }, 3000)
  }

  let { record, error, isLoading } = useShowController({ resource: 'sessions', id: props.sessionId, queryOptions: { onError } })
  let competitorRecord = useShowController({ resource: 'sessions', id: record && record.competitorSessionId ? record.competitorSessionId : props.sessionId, queryOptions: { onError } })

  useEffect(() => {
    if (props.isLive) {
      processUpdate('athlete2', props.socketMessage)
    }

    if (!props.sessionId) {
      setShowHint(false)
      setHintMessage('')
      setScore1(0)
      setScore2(0)
    }
  }, [props.socketMessage])

  useEffect(() => {
    // console.log(props.wsStatus)
  }, [props.wsStatus])

  useEffect(() => {
    if (!props.sessionId) {
      setShowHint(false)
      setHintMessage('')
      setScore1(0)
      setScore2(0)
    }
  }, [props.sessionId])

  useEffect(() => {
    if (record && record.videoUrl) {
      setVideoUrl(record.videoUrl)
    }
  }, [isLoading])

  useEffect(() => {
    const appbar = document.querySelectorAll('header')
    appbar.forEach(appbar => {
      appbar.style.display = "none"
    })
  }, [])

  return (
    <RecordContextProvider value={record}>
      <div style={{ display: 'flex', flexDirection: 'column' }} className="live-view fullscreen">
        <div style={{ display: 'flex', flex: 1 }} className="live-main">
          <div className="live-left" style={{ position: 'relative', flex: 1 }}>
            <div className="live-frame">
              <VideoJS
                id={'athlete1'}
                src={(props.isLive && competitorRecord && competitorRecord.record && competitorRecord.record.videoUrl || record && record.competitorSessionId && competitorRecord && competitorRecord.record && competitorRecord.record.videoUrl) ? competitorRecord.record.videoUrl : ''}
                isPlaying={competitorRecord && competitorRecord.record && competitorRecord.record.videoUrl ? started ? true : false : false }
                onEvent={processUpdate}
                isLive={props.isLive}
                showControls={false}
                replay={replay}
              ></VideoJS>
              <div className="live-frame-footer">
                <ReferenceField source="competitorSessionId" reference="sessions" label="Competitor">
                  <ReferenceField source="userId" reference="users">
                    <DisplayNameField color="black" />
                  </ReferenceField>
                </ReferenceField>
              </div>
            </div>
          </div>

          <div className="live-center" style={{ position: 'relative', flex: 1 }}>
            <div style={{ display: 'flex' }} className="live-header">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} style={{ maxHeight: 30 }} />
              </div>
              <div style={{ flex: 1 }}></div>
            </div>
            <div style={{ margin: 10, padding: 20 }}>
              <div className="live-title-block">
                {props.sessionId ? <div>Thruster / Kettlebell Swing AMREP</div> : null}
              </div>

              <div className="live-sequence" style={{ padding: '30px 30px' }}>
                {props.isLive && !props.sessionId ?
                  <WaitingView wsStatus={props.wsStatus} /> :
                  <LiveProgress
                    didStart={started}
                    isLive={props.isLive}
                    replay={replay}
                    athlete1={competitorRecord && competitorRecord.record ? competitorRecord.record : competitorRecord}
                    athlete2={record}
                    score1={score1}
                    score2={score2}
                  />
                }
                {!props.isLive ? <div style={{width: '100%', textAlign: 'center'}}>
                  <Button style={{ padding: 15 }} label={"Replay"} onClick={(e) => replaySession()}></Button></div> : 
                null}
              </div>

              <div className="live-score live-score-left">
                {(showHintTwo || showHintTwo && props.competitorSessionId) && (
                  <div className="score-hint">
                    <div className="score-hint-title">NO REP</div>
                    <div className="score-hint-message">{hintMessageTwo}</div>
                  </div>
                )}
                <div className="reps">
                  Reps
                </div>
                <span className="score">{score1}</span>
              </div>

              <div className="live-score live-score-right">
                {(showHint && props.isLive || showHint && !props.competitorSessionId) && (
                  <div className="score-hint">
                    <div className="score-hint-title">NO REP</div>
                    <div className="score-hint-message">{hintMessage}</div>
                  </div>
                )}
                <div className="reps">
                  Reps
                </div>
                <span className="score">{score2}</span>
              </div>
              <div style={{ display: 'flex' }} className="live-footer">
                <small style={{ color: '#aaa', textTransform: 'uppercase', fontFamily: 'Industry-Book' }}>
                  Brought to you by Rogue and Beyond the Whiteboard
                </small>
              </div>
            </div>
          </div>

          <div className="live-right" style={{ position: 'relative', flex: 1 }}>
            <div className="live-frame">
              <VideoJS
                id={'athlete2'}
                src={record && record.videoUrl && !props.isLive ? record.videoUrl : ''}
                isPlaying={record && record.videoUrl ? started ? true : false : false}
                onEvent={processUpdate}
                isLive={props.isLive}
                showControls={false}
                replay={replay}
              ></VideoJS>
              <div className="live-frame-footer">
                <ReferenceField source="userId" reference="users" label="Athlete">
                  <DisplayNameField color="black" />
                </ReferenceField>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RecordContextProvider>
  )
}
