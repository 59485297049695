import { Edit, SimpleForm, TextInput } from 'react-admin'

export const EditDivision = (props: any) => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  )
}
