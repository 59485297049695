import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin'

export const EditLeaderboard = (props: any) => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput label="Results" source="results" />
      </SimpleForm>
    </Edit>
  )
}
