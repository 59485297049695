import { useRef, useEffect, useState } from 'react'
import videojs from 'video.js'

import 'video.js/dist/video-js.css'

interface videoProps {
  id: string
  src: string
  isPlaying: boolean
  showControls: boolean
  isLive: boolean
  replay: boolean
  onEvent: (target: string, update: any) => void
}

export const VideoJS = ({ id, src, isPlaying, onEvent, showControls, isLive, replay }: videoProps) => {
  const videoPlayerRef = useRef(null)
  const vtt = src.replace('.mp4', '.vtt')

  const videoJSOptions = {
    autoplay: false,
    controls: showControls,
    fill: true,
    muted: true
  }

  useEffect(() => {
    if (!videoPlayerRef) return

    // @ts-ignore
    const player = videojs(videoPlayerRef.current, videoJSOptions, () => {

      player.on('loadedmetadata', function () {
        var track = player.textTracks()[0]
        track.addEventListener('cuechange', function () {
          // @ts-ignore
          if (track && track.activeCues && track.activeCues[0] && track.activeCues[0].text) {
            // @ts-ignore
            onEvent(id, JSON.parse(track.activeCues[0].text))
          }
        })
      })

      player.on('ended', function () {
        if (isLive) {
          player.src()
          player.load()
        }
      })

      player.src(src)
      player.addRemoteTextTrack({ src: vtt, default: true, kind: 'metadata' }, false)
    })

    return () => {}
  }, [src])

  useEffect(() => {
    if (videoPlayerRef.current) {
      if (replay) {
        // @ts-ignore
        videoPlayerRef.current.pause()
        // @ts-ignore
        videoPlayerRef.current.currentTime = '0'
        // @ts-ignore
        videoPlayerRef.current.play()
      } else {
        if (!isLive) {
          setTimeout(() => {
            if (isPlaying) {
              // @ts-ignore
              videoPlayerRef.current.play()
            }
          }, 3000)
        } else {
          if (isPlaying) {
            // @ts-ignore
            videoPlayerRef.current.play()
          }
        }
      }
    }
  }, [isPlaying, replay])

  return <video muted
    crossOrigin='anonymous'
    id={`video-${id}`}
    ref={videoPlayerRef}
    className="video-js"
  />
}
