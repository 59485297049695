import { faker } from '@faker-js/faker'

const base = () => {
  const status = ['queued', 'complete', 'live', 'cancelled'].sample()

  const actions = {
    queued: ['start'],
    live: ['finish'],
    complete: [],
    cancelled: [],
  }[status]

  return {
    id: faker.datatype.uuid(),
    status: status,
    videoUrl: status == 'complete' ? 'https://video.com/video' : null,
    availableTransitions: actions,
    // score: 25,
    // name: faker.name.findName(),
    // competitor: faker.name.findName(),
    createdAt: faker.date.recent(),
    updatedAt: faker.date.recent(),
  }
}

const adminExtras = () => ({})
// const managerExtras = () => ({})

const admin = () => ({ ...base(), ...adminExtras() })
// const manager = () => ({ ...base(), ...managerExtras() })

export class session {
  static asAdmin = admin
  // static asCompany = manager
}
