import { Button, Link } from "react-admin"

import logo from '../../images/combined-logo.svg'

export function HomeView(props: any) {

  return (
    <div style={{ height: '100vh', display: 'flex', textAlign: 'center', flexDirection: 'column', padding: 40 }}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <div className="live-header">
          <div style={{ alignItems: 'center' }}>
            <img src={logo} style={{ maxHeight: 40 }} />
          </div>
        </div>

        <div style={{ fontSize: 20 }}>
          <p style={{ display: 'flex', flexDirection: 'column' }}><b>Rogue Compete 2022</b> enables you to compete head-to-head with some of the best athletes in the world.</p>

          <p style={{ marginTop: 25 }}>
            <b>FORMAT</b>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              <li>Thrusters (30 seconds)</li>
              <li>Rest (15 seconds)</li>
              <li>Kettlebell Swings (30 seconds)</li>
            </ul>
          </p>
        </div>
        <div>
          <div style={{ marginTop: 40 }}>
            <div>
              <Button component={Link} to="/register" size="large" variant="outlined" color="primary"><b>Register to Compete</b></Button>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button component={Link} to="/leaderboard" size="large" variant="outlined" color="primary"><b>View Leaderboard</b></Button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
