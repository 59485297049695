import { Users } from './users'
import { Leaderboards } from './leaderboards'
import { Queue } from './queue'
import { Events } from './events'
import { Sessions } from './sessions'
import { Divisions } from './divisions'

export default class Admin {
  // resources
  static Users = Users
  static Leaderboards = Leaderboards
  static Queue = Queue
  static Events = Events
  static Sessions = Sessions
  static Divisions = Divisions
}
