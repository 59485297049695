import { faker } from '@faker-js/faker'

const base = () => ({
  id: faker.datatype.uuid(),
  displayWeight: 100,
  createdAt: faker.date.recent(),
  updatedAt: faker.date.recent(),
})

const adminExtras = () => ({})
// export const managerExtras = () => ({})

const admin = () => ({ ...base(), ...adminExtras() })
// export const manager = () => ({ ...base(), ...managerExtras() })

export class division {
  static asAdmin = admin
  // static asCompany = manager
}
