import { FC } from 'react'
import { Button, DeleteWithConfirmButton, FunctionField, useRecordContext } from 'react-admin'

import { apiClient } from '../../apiClient'
import { gql } from '@apollo/client'

interface CustomButtonProps {
  refresh: any
}

const TRIGGER_MUTATE_SESSION = gql`
  mutation mutateSession($id: ID!, $transition: String!) {
    mutateSession(id: $id, transition: $transition) {
      id
      status
    }
  }
`

export const QueueActions = (props: any) => {
  const updateSession = async (record: any, transition: any, refresh: any) => {
    const variables = {
      id: record,
      transition: transition,
    }

    try {
      const { data } = await apiClient.mutate({
        mutation: TRIGGER_MUTATE_SESSION,
        variables,
      })

      refresh()
    } catch (e) {
      console.error(e)
    }
  }

  const MutateButton: FC<CustomButtonProps> = (props: any) => {
    return (
      <button className="queue-button" onClick={() => updateSession(props.record.id, props.transition, props.refresh)}>{props.transition}</button>
    )
  }

  const record = useRecordContext()

  const render = () => {
    return (
      <div className="nobr">
        {record &&
          record.availableTransitions &&
          record.availableTransitions.map((transition: any) => {
            return <MutateButton record={record} transition={transition} {...props} />
          })}

        {record && record.status !== 'live' ? <DeleteWithConfirmButton redirect={'/queue'} label="" /> : <></>}
      </div>
    )
  }

  return <FunctionField {...props} render={render} />
}
