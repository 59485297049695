import { User } from './types'

export default class AppState {
  static getCurrentUser = (): User => {
    const currentUser = localStorage.getItem('currentUser')
    return currentUser ? JSON.parse(currentUser) : null
  }

  static getIdentity = (): { user: User } => {
    return {
      user: AppState.getCurrentUser(),
    }
  }

  static getRoles = (): string[] => {
    const currentUser = AppState.getCurrentUser()
    if (!currentUser) return []
    return ['admin']
  }

  static reset = (): void => {
    AppState.setIdentity({})
  }

  static resetLastPage = (): void => {
    localStorage.removeItem('lastPage')
  }

  static setCurrentUser = (user?: User): void => {
    user ? localStorage.setItem('currentUser', JSON.stringify(user)) : localStorage.removeItem('currentUser')
  }

  static setIdentity = async ({
    user,
  }: {
    user?: User
  }): Promise<void> => {
    AppState.setCurrentUser(user)
  }

  static setLastPage = (url: string): void => {
    localStorage.setItem('lastPage', url)
  }
}
