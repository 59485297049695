import * as React from "react"
import { useState } from "react"
import { useLogin, useNotify, Notification, Button } from "react-admin"

export const LoginPage = () => {
  const [password, setPassword] = useState("")
  
  const login = useLogin()
  const notify = useNotify()
  const submit = (e: any) => {
    e.preventDefault()
    login({ password }).catch(() => notify("Invalid email or password", { type: 'error' }))
  }

  return (
    <div style={{width: '100%', height: '100%', textAlign: 'center', margin: 'auto'}}>
      <form onSubmit={submit} style={{marginTop: '25%'}}>
        <h1>Rogue Compete</h1>
        <input
          style={{height: 35}}
          name="password"
          type="password"
          placeholder="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <br></br>
        <div style={{marginTop: 20}}>
        <Button label={"Sign In"} onClick={(e) => submit(e)}></Button>
        </div>
      </form>
      <Notification />
    </div>
  )
}
