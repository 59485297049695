import { Datagrid, CreateButton, TextField, List, TopToolbar, ReferenceField, SearchInput } from 'react-admin'
import { UserField } from '../../core/UserField'

const UserListActions = () => (
  <TopToolbar>
    <CreateButton resource="users" label="Register an Athlete" />
  </TopToolbar>
)

const filters = [<SearchInput source="q" alwaysOn />]

export const Table = () => (
  <List actions={<UserListActions />} filters={filters}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <UserField label="Athlete" source="name" />
      <ReferenceField source="divisionId" reference="divisions">
        <TextField source="label" />
      </ReferenceField>
    </Datagrid>
  </List>
)
