import { EditLeaderboard } from './edit'
import { Table } from './table'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
// Alt choice: import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';

export class Leaderboards {
  static Edit = EditLeaderboard
  static List = Table
  static Icon = EmojiEventsOutlinedIcon
}
