import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  // DLR: Disabled because it causes UseEffect to be called twice in Development mode
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
