import './index.css'

export function WaiverView(props: any) {

  return (
    <>
      <div style={{}}>
        <h3>ROGUE FITNESS</h3>
        <h4>WAIVER – PARTICIPANT</h4>
        <p>Please read the following carefully before signing:</p>
      </div>

      <ul className='waiver-view'>
        <li>I freely, voluntarily, and without duress execute the waiver below. I understand and agree that:</li>
        <li>In the course of participating in activities at or related Rogue Fitness at the 2022 CrossFit Games (the “Event”), I understand that my activities include strenuous activity and work that may be hazardous to me including, but not limited to, athletic movements, workouts and related activities (collectively, the “Activities”). I HEREBY EXPRESSLY AND SPECIFICALLY ASSUMES THE RISK OF INJURY OR HARM IN THE ACTIVITIES and release Rogue, the event sponsors, and their respective members, shareholders, officers, employees, contractors, vendors, successors, affiliates and assigns (collectively, the “Releasees”) from all liability of injury, illness, death, or property damage resulting from the Activities.</li>
        <li>I will follow all social distancing and other guidelines related to COVID-19 during the course of my participation in the event.</li>
        <li>I hereby release and forever discharge and hold harmless the Releasees from any and all liability, claims, and demands of whatever kind or nature, either in law or in equity, which arise or may hereafter arise from my Activities with Rogue and the Event. I understand and agree that this Release discharges the Releasees from any liability or claim that I may have against any of them with respect to any bodily injury, personal injury, illness, death, or property damage that may result from my Activities with the Event, whether caused by the negligence of any Releasee or otherwise. I also understand that Rogue does not assume any responsibility for or obligation to provide financial assistance or other assistance, including but not limited to medical, health, or disability insurance in the event of injury or illness.</li>
        <li>I consent to administration of first aid and/or medical treatment, and agree to indemnify, waive, release, covenant not to sue, and forever discharge Releasees from any and all liability or claims arising out of such treatment. I hereby release and forever discharge each of the Releasees from any claim whatsoever which arises or may hereafter arise on account of any first aid, treatment, or service rendered in connection with my Activities with the Event.</li>
        <li>I understand that neither Rogue nor any other Releasee carries or maintains health, medical, or disability insurance coverage for any participant. I UNDERSTAND AND AGREE THAT I AM EXPECTED AND ENCOURAGED TO OBTAIN MY OWN MEDICAL OR HEALTH INSURANCE COVERAGE.</li>
        <li>I hereby grant and convey unto Rogue all right, title, and interest in any and all photographic images and video or audio recordings made, and any data collected, by Rogue or its assigns during the my Activities with Rogue, including, but not limited to, any royalties, proceeds, or other benefits derived from such photographs or recordings.</li>
        <li>I expressly agree that this Release is intended to be as broad and inclusive as permitted by the laws of the State of Ohio, and that this Release shall be governed by and interpreted in accordance with the laws of the State of Ohio. I agree that in the event that any clause or provision of this Release shall be held to be invalid by any court of competent jurisdiction, the invalidity of such clause or provision shall not otherwise affect the remaining professions of this Release which shall continue to be enforceable.</li>
      </ul>
    </>
  )
}