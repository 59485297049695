import moment from 'moment'

import { FieldProps, useRecordContext } from 'react-admin'

export const timeAgo = (timestamp: moment.MomentInput, suffix: boolean) => {
  if (!timestamp) return null

  const m = moment(timestamp)
  return <span title={m.format('dddd, MMMM Do YYYY, h:mm a')}>{m.fromNow(!suffix)}</span>
}

interface TimeAgoFieldProps extends FieldProps {
  suffix?: boolean
}

export const TimeAgoField: React.FC<TimeAgoFieldProps> = ({ source, suffix = false }) => {
  const record = useRecordContext()
  if (!source) return null
  return timeAgo(record[source], suffix)
}
