import { useRecordContext } from 'react-admin'

export const LeaderboardNameField = ({ color = 'inherit' }) => {
  const record = useRecordContext()

  const flag = <span style={{ marginLeft: 10, marginRight: 10 }} className={`fi fi-${record['userCountry'] || 'us'}`}></span>
  return (
    <span className="nobr">
      {flag}
      <span style={{ fontWeight: 'bold', color: color, textTransform: 'uppercase' }}>{record['userDisplayName']}</span>
    </span>
  )
}
