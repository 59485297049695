import { FC, useEffect } from 'react'

import {
  Datagrid,
  List,
  AutocompleteInput,
  SimpleForm,
  ReferenceInput,
  ReferenceField,
  useRefresh,
  SelectInput
} from 'react-admin'

import { Typography, useMediaQuery } from '@mui/material'

import { useForm } from 'react-hook-form'

import { TimeAgoField } from '../../core/TimeAgoField'
import { QueueActions } from './actions'
import { UserField } from '../../core/UserField'

import { apiClient } from '../../apiClient'
import { webSocket } from '../../websocket'
import { gql } from '@apollo/client'

import './queue.css'

const CREATE_SESSION = gql`
  mutation createSession($userId: ID!, $status: String!, $eventId: ID, $competitorSessionId: ID) {
    createSession(userId: $userId, status: $status, eventId: $eventId, competitorSessionId: $competitorSessionId) {
      id
    }
  }
`

type QueueProps = {}

export const Table: FC<QueueProps> = ({}) => {
  const { reset } = useForm()

  const refresh = useRefresh()

  // websocket listener
  useEffect(() => {
    webSocket.onmessage = function (event: any) {
      let json = JSON.parse(event.data)
      if (json['event'] && json['event'] === 'end_workout') refresh()
    }

    return () => webSocket.close()
  }, [])

  const createSession = async (data: any) => {
    const variables = {
      userId: data.userId,
      competitorSessionId: data.competitorSessionId,
      status: 'queued'
    }

    try {
      const {
        data: { createSession: session },
      } = await apiClient.mutate({ mutation: CREATE_SESSION, variables })

      refresh()
      reset()
    } catch (e) {
      console.error(e)
    }
  }

  const optionRenderer = (record: any) => {
    return `${record.divisionName} - ${record.userDisplayName} ${record && record.score ? '(' + record.score + ' reps)' : ''}`
  }

  const mobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  const QuickAddForm = () => {
    return (
      <SimpleForm defaultValues={{}} onSubmit={createSession} toolbar={false} redirect={false}>
        <div className={'queue-list-form-wrap'}>
          <div className={'queue-list-form'}>
            <ReferenceInput source="eventId" reference="events">
              <SelectInput disabled={true} defaultValue='roguecompete' label="Event" source="eventId" choices={[
                { id: 'roguecompete', name: 'Rogue Compete' }
              ]} />
            </ReferenceInput>
            {/* <BooleanInput label="Sandbox Mode" source="sandbox" /> */}
          </div>

          <div className={'queue-list-form'}>
            <ReferenceInput source="userId" reference="users">
              <AutocompleteInput label="Athlete" fullWidth helperText={false} />
            </ReferenceInput>

            <Typography style={{ margin: 5 }}>vs</Typography>

            <ReferenceInput source="competitorSessionId" reference="sessions" filter={{ status: 'complete', type: "competitor" }}>
              <AutocompleteInput optionText={optionRenderer} label="Competitor" fullWidth helperText={false} />
            </ReferenceInput>

            <div style={{ padding: 10 }}>
              <button type="submit" className="queue-button">Add to Queue</button>
            </div>
          </div>
        </div>
      </SimpleForm>
    )
  }

  return (
    <List
      // aside={<Aside />}
      title="Live Stage"
      resource="sessions"
      // filter={{ status: ['live', 'queued'] }}
      filter={{ queue: ['live', 'queued'] }}
      exporter={false}
    >
      <>
        <QuickAddForm />

        <Datagrid bulkActionButtons={false} size="medium">
          <ReferenceField label="Athlete" source="userId" reference="users">
            <UserField />
          </ReferenceField>

          <ReferenceField source="competitorSessionId" reference="sessions" label="Competitor" sortable={false}>
            <ReferenceField source="userId" reference="users">
              <UserField />
            </ReferenceField>
          </ReferenceField>

          {mobile ? null : <TimeAgoField source="createdAt" label="Wait" sortable={false} />}

          <QueueActions refresh={refresh} label="Actions" />
        </Datagrid>
      </>
    </List>
  )
}
